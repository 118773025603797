<template>
  <b-table
    ref="table"
    :no-provider-sorting="true"
    :items="donations"
    :fields="columns"
    responsive
    empty-text="No Donations found"
    show-empty
  />
</template>
<script>
export default {
  name: 'TaxReceiptTable',
  props: {
    donations: { type: Array, default: () => [] },
  },
  data() {
    return {
      columns: [
        { key: 'transaction_id' },
        { key: 'total_amount', formatter: value => `${value.toFixed(2)} $` },
        { key: 'net_amount', formatter: value => `${value.toFixed(2)} $` },
        { key: 'donation_type' },
        { key: 'payment_method' },
        { key: 'note' },
        { key: 'created_at', label: 'Donation Date' },
      ],
    }
  },
}
</script>
<style lang="scss">

</style>

<template>
  <validation-observer v-slot="{handleSubmit}">
    <b-form @submit.prevent="handleSubmit(sendToEmail)">
      <select-years :year.sync="year" />
      <b-button
        type="submit"
        variant="primary"
      >
        Send
      </b-button>
    </b-form>
  </validation-observer>
</template>
<script>
import SelectYears from './SelectYear.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  name: 'SendTransactionsToEmail',
  components: { SelectYears },
  props: {
    userId: { type: Number, default: 0 },
  },
  data() {
    return {
      year: '',
    }
  },
  setup() {
    const { successfulOperationAlert, showErrors } = handleAlerts()
    return {
      successfulOperationAlert, showErrors,
    }
  },
  methods: {
    sendToEmail() {
      this.$payment.get('internalops/sendDonationToMail', {
        params: {
          user_id: this.userId,
          entity_id: this.$store.getters['mainEntity/getEntityId'],
          year: this.year,
        },
      }).then(() => {
        this.successfulOperationAlert('Donations is sent to email')
      }).catch(({ response }) => {
        this.showErrors(response.data.errors)
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
